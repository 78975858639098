import { articles } from "./data";
import { useState } from "react";

function App() {
  return (
    <div className="container">
      <Header />
      <Propagation />
      <Links />
      <Footer />
    </div>
  );
}

function Header() {
  return (
    <div className="comp header">
      <h1>
        <span id="geeky">Geeky</span>
        <br />
        <span id="report">Report</span>
        <br />
        <span id="com">.com</span>
      </h1>
    </div>
  );
}

function Propagation() {
  return (
    <div className="comp propagation">
      <a href="https://twitter.com/GeekyReportCom" title="" target="_blank">
        <img
          src="imgs/x.png"
          title="Geeky Report on X"
          alt="Geeky Report on X"
          className="icon"
        />
      </a>
      <a
        href="https://www.facebook.com/geekyreportcom"
        title=""
        target="_blank"
      >
        <img
          src="imgs/facebook.png"
          title="Geeky Report on Facebook"
          alt="Geeky Report on Facebook"
          className="icon"
        />
      </a>
      <img
        src="imgs/threads.png"
        title="Geeky Report on on Threads - coming soon!"
        alt="Geeky Report on Threads - coming soon!"
        className="icon"
      />
    </div>
  );
}

function Links() {
  const [perColumn, setPerColumn] = useState(9);
  const [showShow, setShowShow] = useState(true);

  function showMore() {
    setPerColumn(perColumn + 10);
    setShowShow(false);
  }

  return (
    <div className="comp links">
      <h2>
        Your daily dose of hand-picked news from tech, science, investing,
        sci-fi and much more
      </h2>

      {articles.slice(0, perColumn).map((article, i) => (
        <Article
          key={article.key}
          source={article.source}
          linktext={article.linktext}
          url={article.url}
          topics={article.topics}
          style={{
            animation: `headline-animation 0.5s ${
              i < 9 ? i++ / 4 : (-8 + i++) / 4
            }s forwards`,
          }}
        />
      ))}
      {showShow && (
        <button className="show_button" onClick={showMore}>
          Show more...
        </button>
      )}
    </div>
  );
}

function Article({ source, url, linktext, topics, style }) {
  return (
    // <div className="headline" style={style}>
    //   <div className="from">{source}</div>
    //   <p>
    //     <a href={url} target="about_blank" title={linktext} className="stream">
    //       {linktext}
    //     </a>
    //   </p>
    //   {topics.map((topic, i) => (
    //     <Topic topic={topic} key={i} />
    //   ))}
    // </div>
    <div className="headline" style={style}>
      <p>
        <a href={url} target="about_blank" title={linktext} className="stream">
          {linktext}
        </a>
      </p>
      <div className="from">{source}</div>
    </div>
  );
}

// function Topic({ topic }) {
//   return <span className="topic">{topic}</span>;
// }

function Footer() {
  return <div className="comp footer">2024 © GeekyReport.com</div>;
}

export default App;
