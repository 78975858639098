const articles = [
  {
    key: "00019",
    linktext:
      "According to Elon Musk, AI will be smarter than any human in 2025 already",
    url: "https://www.reuters.com/technology/teslas-musk-predicts-ai-will-be-smarter-than-smartest-human-next-year-2024-04-08/",
    source: "Reuters.com",
  },
  {
    key: "00018",
    linktext:
      "Can wearable tech help with anxiety and insomnia? Startup called Neurotech thinks it can",
    url: "https://techcrunch.com/2024/04/08/neurovalens-anxiety-fda/",
    source: "TechCrunch.com",
  },
  {
    key: "00017",
    linktext:
      "USA and UK agree on safety rules for AI testing. Will it be enough to keep us safe?",
    url: "https://www.ft.com/content/4bafe002-4e38-4d2b-94e0-5a0d319252c5",
    source: "FT.com",
  },
  {
    key: "00016",
    linktext:
      "Downfall of FTX founder Sam Bankman-Fried and the weird ideology of Effective Altruism",
    url: "https://www.wired.com/story/deaths-of-effective-altruism/",
    source: "Wired.com",
  },
  {
    key: "00015",
    linktext: "Farming on the Moon is one step closer now",
    url: "https://www.stuff.co.nz/world-news/350231659/nasa-cultivate-crops-moon",
    source: "Stuff.co.nz",
  },
  {
    key: "00014",
    linktext: "Rivian R2 interior review - Big return of the wooden trim?",
    url: "https://www.motortrend.com/reviews/2026-rivian-r2-electric-suv-interior-review/",
    source: "Motortrend.com",
  },
  {
    key: "00013",
    linktext:
      "Map of upcoming total solar eclipse over USA in April 2024; Cities and times listed",
    url: "https://science.nasa.gov/eclipses/future-eclipses/eclipse-2024/where-when/",
    source: "NASA.gov",
  },
  {
    key: "00012",
    linktext:
      "Xiaomi's new SU7 electric vehicle stuns with design and cheaper than expected price tag",
    url: "https://electrek.co/2024/03/28/tesla-smartphonexiaomi-su7-ev-offers-434-mile-range-under-30k/",
    source: "Electrec.co",
  },
  {
    key: "00011",
    linktext: "IS EV startup Fisker running out of money?",
    url: "https://www.reuters.com/business/autos-transportation/ev-startup-fisker-sell-up-1667-mln-senior-secured-convertible-notes-2024-03-18/",
    source: "Reuters.com",
  },
  {
    key: "00010",
    linktext: "Upcoming Bitcoing halving might lead to a price action",
    url: "https://www.investing.com/news/cryptocurrency-news/bitcoin-price-prediction-what-is-the-impact-of-halving-on-btc-3341834",
    source: "Investing.com",
  },
  {
    key: "00009",
    linktext:
      "Devin AI keeps improving in fixing code bugs. Is it coming for your programming job?",
    url: "https://devops.com/cognition-labs-previews-devin-ai-software-engineer/",
    source: "DevOps.com",
  },
  {
    key: "00008",
    linktext:
      "Large secret satellite network called Starshield is already being deployed",
    url: "https://www.reuters.com/technology/space/musks-spacex-is-building-spy-satellite-network-us-intelligence-agency-sources-2024-03-16/",
    source: "Reuters.com",
  },
  {
    key: "00007",
    linktext:
      "Women-centric dating app Bumble hopes that AI could help reinvent their business model",
    url: "https://www.bbc.com/worklife/article/20240315-lidiane-jones-bumble-ai-bbc-executive-interview",
    source: "BBC.com",
  },
  {
    key: "00006",
    linktext:
      "Dune 2 earned in 10 days more $$$ than the first movie during the whole time",
    url: "https://www.gamespot.com/articles/dune-2-nears-huge-box-office-milestone-passes-first-movies-entire-run-after-10-days/1100-6521893/",
    source: "GameSpot.com",
  },
  {
    key: "00005",
    linktext:
      "Dark matter might not be a real thing after all as daring scientist proposes a competing theory",
    url: "https://www.jpost.com/science/space/article-792369",
    source: "JPost.com",
  },
  {
    key: "00004",
    linktext:
      "Standartized tests are returning to college admission processes after years of social experimens",
    url: "https://www.foxnews.com/media/us-colleges-bring-back-standardized-testing-finding-test-optional-policies-hurt-minority-students",
    source: "FoxNews.com",
  },
  {
    key: "00003",
    linktext:
      "AI collaboration between Apple and Google that iPhone users might benefit from in the future",
    url: "https://techcrunch.com/2024/03/17/apple-is-reportedly-exploring-a-partnership-with-google-for-gemini-powered-feature-on-iphones/",
    source: "TechCrunch.com",
  },
  {
    key: "00002",
    linktext:
      "Anduril delivers on creating even bigger loitering munition as the new drone tech passes the test",
    url: "https://www.suasnews.com/2024/03/altius-700m-hits-all-targets-in-successful-test-of-largest-loitering-munition-on-the-market/",
    source: "SUASNews.com",
  },
  {
    key: "00001",
    linktext:
      "Qualcomm releases a new AI-oriented chip with impressive parameters",
    url: "https://finance.yahoo.com/news/qualcomm-debuts-ai-focused-snapdragon-8s-gen-3-chip-063051782.html",
    source: "Finance.Yahoo.com",
  },
];

export { articles };
